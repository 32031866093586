import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  useNavigate,
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../../features/accounts/useAuth";
import { apiUrl } from "../../services/apiClient";
import { useRecaptcha } from "../../components/forms/Recaptcha";
import { useCallback, useEffect, useRef, useState } from "react";
import { loadExtUser as loadExternalUser } from "../../features/accounts/auth.service";
import { ProcessButton } from "../../components/buttons/ProcessButton";
import { useAccountForm } from "./useAccountForm";

type SignupData = {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  provider?: string;
  externalEmail?: string;
  inviteCode?: string;
  shopify?: string;
  urlReferrer?: string;
  signUpSource?: string;
};

const required = ["email", "password", "firstName", "lastName"];
const fields = [...required, "businessName"];

const Signup = () => {
  const navigate = useNavigate();
  const { doSignup } = useAuth();

  const { token, isLoadingToken, regenerateToken } = useRecaptcha("signup");

  const [validExtUser, setValidExtUser] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    data,
    setData,
    errors,
    setErrors,
    isValid,
    validateField,
    handleInputChange,
    handleSubmit,
    isProcessing,
    serverError,
  } = useAccountForm<SignupData>({
    fields,
    required: searchParams.get("ic") ? required : fields,
    validExtUser,
  });

  const externalUrl =
    apiUrl +
    `/auth/external-login?returnUrl=` +
    encodeURIComponent(window.location.origin + "/external-login") +
    "&ic=" +
    encodeURIComponent(data?.inviteCode || "");

  const setExtUser = useCallback(
    async (extEmail: string) => {
      const extUser = await loadExternalUser(extEmail);
      if (extUser) {
        setData({
          ...data,
          firstName: extUser.firstName,
          lastName: extUser.lastName,
          email: extEmail,
          provider: extUser.provider,
          externalEmail: extUser.emailExists ? null : extEmail,
        });

        if (extUser.emailExists) {
          setErrors({
            ...errors,
            email:
              "The email you have entered is already in use. Please sign in using this email, or create a new account with a different email and password.",
          });
        } else {
          setValidExtUser(!!extUser.firstName && !!extUser.lastName);
        }
      }
    },
    [errors, setErrors, setData, data]
  );

  useEffect(() => {
    let d = {} as SignupData;

    // URL Parameters
    const referrerParam = searchParams.get("referrer");
    const srcParam = searchParams.get("src");
    const sectionParam = searchParams.get("section");

    const referrer = referrerParam
      ? decodeURIComponent(referrerParam)
      : document.referrer || window.location.href;

    d = { ...d, urlReferrer: referrer };

    const source = sectionParam
      ? decodeURIComponent(sectionParam)
      : srcParam
      ? decodeURIComponent(srcParam)
      : window.location.pathname.replace(/^\//, "");
    if (source) {
      d = { ...d, signUpSource: source };
    }

    // Query parameters
    if (searchParams.get("email") && !data.email) {
      d = { ...d, email: searchParams.get("email") };
    }
    if (searchParams.get("ee")) {
      const extEmail = searchParams.get("ee");
      d = { ...d, email: extEmail };
      searchParams.delete("ee");
      setSearchParams(searchParams);
      setExtUser(extEmail);
    }
    if (searchParams.get("ic") && !data.inviteCode) {
      d = { ...d, inviteCode: searchParams.get("ic") };
    }
    const org = searchParams.get("org");
    if (org) {
      d = { ...d, businessName: org };
      localStorage.setItem("businessName", org);
    }
    const shopify = searchParams.get("shopify");
    if (shopify) {
      d = { ...d, shopify };
      localStorage.setItem("shopify-store", shopify);
    }
    const fn = searchParams.get("fn");
    if (fn) {
      //split to first and last name
      const names = fn.split(" ");
      d = { ...d, firstName: names[0], lastName: names[1] };
      localStorage.setItem("fullName", fn);
    }

    // LocalStorage items
    const storedShopify = localStorage.getItem("shopify-store");
    if (storedShopify && !d.shopify) {
      d = { ...d, shopify: storedShopify };
    }
    const storedBusinessName = localStorage.getItem("businessName");
    if (storedBusinessName && !d.businessName) {
      d = { ...d, businessName: storedBusinessName };
    }

    const storedFullName = localStorage.getItem("fullName");
    if (storedFullName && !d.firstName && !d.lastName) {
      const names = storedFullName.split(" ");
      d = { ...d, firstName: names[0], lastName: names[1] };
    }

    // Only update if there are changes
    const hasChanges = Object.keys(d).some((key) => d[key] !== data[key]);
    if (hasChanges) {
      setData((prevData) => ({ ...prevData, ...d }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setExtUser, setSearchParams]); // Remove data and setData from dependencies

  const onSubmit = async () => {
    let currentToken = token;
    if (isLoadingToken || !currentToken) {
      currentToken = await regenerateToken();
      if (!currentToken) {
        setErrors({
          ...errors,
          email: "Unable to verify reCAPTCHA. Please try again.",
        });
        return;
      }
    }

    handleSubmit(
      "Sign up",
      async () => {
        const response = await doSignup({ ...data, token: currentToken });
        if (response.succeeded) {
          navigate(data.inviteCode ? "/" : "/start", { replace: true });
        } else {
          await regenerateToken();
        }
        return { success: response.succeeded, message: response?.message };
      },
      async () => {
        await regenerateToken();
      }
    );
  };
  return (
    <Container sx={{ pt: 5, pb: 5 }} maxWidth="sm">
      <Box mb={8} display="flex" justifyContent="center">
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo.png" />
        </RouterLink>
      </Box>
      <Card>
        <CardContent
          sx={{
            padding: 4,
            display: "flex",
            flexDirection: "column",
            minHeight: 400,
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h2">
                Sign up
              </Typography>
            </div>
          </Box>

          <Grid container spacing={3}>
            <Grid size={{ md: 12, xl: 7 }}>
              <Button
                size="large"
                variant="outlined"
                href={externalUrl + "&provider=Google"}
              >
                <img
                  alt="Google"
                  style={{
                    marginRight: "16px",
                  }}
                  src="/images/google.svg"
                />
                Sign up with Google
              </Button>
            </Grid>
            <Grid size={{ md: 12, xl: 5 }} sx={{ textAlign: "right" }}>
              <Button
                size="large"
                variant="outlined"
                href={externalUrl + "&provider=Facebook"}
              >
                <img
                  alt="Facebook"
                  style={{
                    marginRight: "16px",
                    width: 24,
                    height: 24,
                  }}
                  src="/images/facebook.svg"
                />
                Facebook
              </Button>
            </Grid>
          </Grid>
          {!data.provider && (
            <Box mt={4}>
              <Typography color="textPrimary">
                or sign up with email and password:
              </Typography>
            </Box>
          )}
          {data.provider && (
            <Alert severity="success" sx={{ mt: 2 }}>
              You&quot;ve successfully authenticated with <b>{data.provider}</b>
              .{" "}
              {!data.inviteCode && (
                <span>Please enter required sign up information.</span>
              )}
            </Alert>
          )}
          {serverError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {serverError}
            </Alert>
          )}
          <Box>
            <form>
              {!validExtUser && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    sx={{ mt: 2 }}
                    error={Boolean(errors.firstName)}
                    fullWidth
                    helperText={errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={() => validateField("firstName")}
                    onChange={handleInputChange}
                    value={data.firstName}
                    variant="outlined"
                    required
                    inputRef={inputRef}
                    autoComplete="given-name"
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    error={Boolean(errors.lastName)}
                    fullWidth
                    helperText={errors.lastName}
                    label="Last Name"
                    name="lastName"
                    onBlur={() => validateField("lastName")}
                    onChange={handleInputChange}
                    value={data.lastName}
                    variant="outlined"
                    required
                    autoComplete="family-name"
                  />
                </Box>
              )}

              {!data.inviteCode && (
                <TextField
                  sx={{ mt: 3 }}
                  error={Boolean(errors.businessName)}
                  fullWidth
                  helperText={errors.businessName}
                  label="Business Name"
                  name="businessName"
                  onBlur={() => validateField("businessName")}
                  onChange={handleInputChange}
                  value={data.businessName}
                  variant="outlined"
                  required
                />
              )}

              {!validExtUser && (
                <TextField
                  sx={{ mt: 3 }}
                  error={Boolean(errors.email)}
                  fullWidth
                  helperText={errors.email}
                  label="Email"
                  name="email"
                  onBlur={() => validateField("email")}
                  onChange={handleInputChange}
                  type="email"
                  value={data.email}
                  variant="outlined"
                  slotProps={{
                    inputLabel: { shrink: Boolean(data.email) },
                  }}
                  autoComplete="email"
                  required
                />
              )}

              {!validExtUser && (
                <TextField
                  sx={{ mt: 3 }}
                  error={Boolean(errors.password)}
                  fullWidth
                  helperText={errors.password}
                  label="Password"
                  name="password"
                  onBlur={() => validateField("password")}
                  onChange={handleInputChange}
                  type="password"
                  value={data.password}
                  variant="outlined"
                  slotProps={{
                    inputLabel: { shrink: Boolean(data.password) },
                  }}
                  required
                />
              )}

              <Box mt={3}>
                <ProcessButton
                  onClick={onSubmit}
                  size="large"
                  disabled={!isValid || isProcessing || isLoadingToken}
                  processing={isProcessing}
                  label={data.inviteCode ? "Join Your Team" : "Create Account"}
                  color={"primary"}
                  variant="contained"
                  fullWidth
                />
              </Box>
            </form>
            <Box sx={{ mt: 1, fontSize: 12 }}>
              By <strong>signing up</strong> you agree to the{" "}
              <a
                href="https://www.catalogmachine.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.catalogmachine.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Box>
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary" align="center">
              Already have an account?{" "}
              <Link
                component={RouterLink}
                to={"/login"}
                variant="body2"
                color="secondary"
              >
                Sign in
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Typography
        component="div"
        sx={{
          textAlign: "center",
          mt: 3,
          color: "#999",
          fontSize: 10,
          a: { color: "#999" },
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </Typography>
    </Container>
  );
};

export default Signup;
