import { Box, IconButton } from "@mui/material";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
  gridExpandedSortedRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useAtom } from "jotai";
import { IconTypes } from "../../../components/icons.types";
import { useDialogs } from "../../../contexts/useDialogs";
import { getProductTemplate } from "../../../features/catalogs/catalog.func";
import {
  getProductFieldValue,
  DefaultImageField,
  moveEntry,
} from "../../../features/data/data-types";
import { ProductRef } from "../../../features/products/product";
import {
  ProductFilter,
  useProducts,
} from "../../../features/products/product-func";
import { productDbAtom } from "../../../features/products/product.state";
import { templateDbAtom } from "../../../features/templates/template.state";
import { startElementDrag, endElementDrag } from "../../designer/designer.func";
import { EmptyProductDb } from "../EmptyProductDb";
import { useState, useCallback, useMemo, useEffect } from "react";
export type ListAction = "add" | "remove" | "sort" | "drag" | "expand";

interface ProductListProps {
  productIds?: string[];
  excludeIds?: string[];
  actions?: ListAction[];
  onAction?: (action: ListAction, ids: string[]) => void;
  onClick?: (ref: ProductRef) => void;
  filter?: ProductFilter;
  fixed?: boolean;
  rowClassName?: (params: GridRowParams) => string;
  emptyState?: React.ReactNode;
  fields?: string[];
}

export const ProductList = ({
  productIds,
  excludeIds,
  actions,
  onAction,
  onClick,
  filter,
  fixed,
  rowClassName,
  emptyState,
  fields,
}: ProductListProps) => {
  const [productDb] = useAtom(productDbAtom);
  const [templateDb] = useAtom(templateDbAtom);

  const { productModalApi } = useDialogs();

  const [ids, setIds] = useState<string[]>([]);
  //const [rowCount, setRowCount] = useState(50);
  const { searchProducts } = useProducts();
  const apiRef = useGridApiRef();

  const draggable = actions?.includes("drag");

  const total = productIds?.length ?? 0;

  const NoRowsOverlay = useCallback(() => {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {emptyState ?? <div>No products</div>}
      </Box>
    );
  }, [emptyState]);

  const Footer = () => {
    return (
      <Box
        sx={{
          p: 1,
          mr: 2,
          textAlign: "right",
        }}
      >
        products: <strong>{allRows.length}</strong>{" "}
        {total > 0 && allRows.length < total ? " / " + total : ""}
      </Box>
    );
  };

  useEffect(() => {
    const list = (productIds ?? Array.from(productDb.productMap.keys())).filter(
      (id) => !excludeIds?.includes(id)
    );
    setIds(list);
  }, [excludeIds, productDb.productMap, productIds]);

  const productColumns: GridColDef[] = useMemo(() => {
    const action = actions?.includes("add")
      ? "add"
      : actions?.includes("remove")
      ? "remove"
      : null;
    const a: GridColDef[] =
      action && onAction
        ? [
            {
              field: "action",
              headerName: "",
              width: 50,
              disableColumnMenu: true,
              sortable: false,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <Box>
                    <IconButton
                      className="action"
                      aria-label={action}
                      size="small"
                      onClick={() => onAction?.(action, [params.row.id])}
                    >
                      {action === "add" ? (
                        <IconTypes.AddAction />
                      ) : (
                        <IconTypes.RemoveAction />
                      )}
                    </IconButton>
                  </Box>
                );
                //}
              },

              renderHeader: () => {
                if (action !== "add" || !onAction) return "";
                return (
                  <IconButton
                    className="action"
                    aria-label={action}
                    size="small"
                    title="Add all products"
                    onClick={() => {
                      const ids = Array.from(
                        gridExpandedSortedRowEntriesSelector(apiRef)
                        //apiRef.current.getVisibleRowModels().keys()
                      )
                        .slice(0, 100)
                        .map((r) => r.id as string);
                      onAction?.(action, ids);
                    }}
                    disabled={!ids.length}
                  >
                    <ControlPointDuplicateIcon />
                  </IconButton>
                );
              },
            },
          ]
        : [];

    const fieldCols =
      fields?.map((f) => ({
        field: f.toLowerCase(),
        headerName: f,
        sortable: !fixed,
        valueGetter: (_value, row) =>
          getProductFieldValue(row?.$ref as ProductRef, f, false),
        renderCell: (params: GridRenderCellParams) => {
          const value = getProductFieldValue(
            params.row?.$ref as ProductRef,
            f,
            true
          );
          return <>{value?.toString() ?? ""}</>;
        },
      })) ?? [];

    const cols: GridColDef[] = [
      {
        field: "thumb",
        headerName: "",
        disableColumnMenu: true,
        width: 60,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const r = params.row.$ref as ProductRef;

          return (
            <Box
              className="thumb-cell"
              draggable={draggable}
              onDragStart={(e) =>
                startElementDrag(e, {
                  type: "product",
                  contentType: r.thumb ? "image" : "text",
                  content: r.thumb ? DefaultImageField : "{{Name}}",
                  productId: r.id,
                })
              }
              onDragEnd={endElementDrag}
              style={{ cursor: draggable ? "move" : "pointer" }}
            >
              {r.thumb && (
                <img src={r.thumb} alt={r.product.code} draggable={false} />
              )}
            </Box>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 80,
        sortable: !fixed,
      },
      {
        field: "code",
        headerName: "Code",
        flex: 1,
        sortable: !fixed,
      },
      ...fieldCols,
      {
        field: "actions",
        type: "actions",
        width: 50,
        getActions: (params: GridRowParams) => {
          const p = params.row.$ref as ProductRef;
          const a = [
            <GridActionsCellItem
              icon={<IconTypes.EditAction />}
              label="Edit"
              key="1"
              onClick={() => {
                productModalApi.openEditProduct({
                  productId: p.product.id,
                });
              }}
            />,
          ];
          return a;
        },
      },
    ];
    if (draggable) {
      cols.unshift({
        field: "drag",
        headerName: "",
        width: 50,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const r = params.row.$ref as ProductRef;
          return (
            <Box
              className="thumb-cell"
              draggable
              onDragStart={(e) =>
                startElementDrag(e, {
                  type: "product",
                  contentType: "template",
                  productId: r.id,
                  templateId: r.thumb
                    ? undefined
                    : getProductTemplate(templateDb, r)?.template.id,
                })
              }
              onDragEnd={endElementDrag}
              style={{ cursor: "move" }}
            >
              <IconTypes.Drag />
            </Box>
          );
        },
      });
    }
    if (actions?.includes("expand")) {
      cols.push({
        field: "arrow",
        headerName: "",
        width: 50,
        disableColumnMenu: true,
        sortable: false,
        renderCell: () => {
          return <ChevronRightIcon sx={{ color: "text.secondary" }} />;
        },
      });
    }
    return [...a, ...cols];
  }, [
    actions,
    apiRef,
    draggable,
    fields,
    fixed,
    ids.length,
    onAction,
    productModalApi,
    templateDb,
  ]);

  const productToRow = (p: ProductRef) => ({
    id: p.id,
    __reorder__: p.product.name,
    name: p.product.name,
    code: p.product.code,
    $ref: p,
  });

  const allRows = useMemo(() => {
    return searchProducts(filter, ids).map(productToRow);
  }, [filter, ids, searchProducts]);

  const productRows = useMemo(() => {
    //const rows = [...allRows];
    //if (rowCount > 0 && rows.length > rowCount) return rows.slice(0, rowCount);
    return allRows;
  }, [allRows]);

  const handleOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const list = moveEntry(ids, params.oldIndex, params.targetIndex);
      setIds(list);
      onAction?.("sort", list);
    },
    [ids, onAction]
  );

  if (productDb.productMap.size === 0)
    return emptyState ? (
      <>{NoRowsOverlay()}</>
    ) : (
      <EmptyProductDb
        onAdd={() => {
          productModalApi.openEditProduct({});
        }}
      />
    );

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: 100,
        minWidth: 250,
        display: "flex",
        flex: 1,
        overflow: "hidden",
        "& .MuiDataGrid-root": {
          flex: 1,
          width: "100%",
          height: "100% !important",
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          "& .MuiDataGrid-actionsCell": {
            display: "none",
          },
          "&:hover": {
            "& .MuiDataGrid-actionsCell": {
              display: "inline-flex",
            },
          },
        },
        "& .thumb-cell": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          "& img": {
            maxHeight: 30,
            maxWidth: 30,
          },
        },
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        rows={productRows}
        columns={productColumns}
        rowReordering={actions?.includes("sort")}
        onRowOrderChange={handleOrderChange}
        onRowClick={(p) => onClick?.(p.row.$ref as ProductRef)}
        getRowClassName={rowClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          footer: Footer,
        }}
      />
    </Box>
  );
};
