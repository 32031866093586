import { Alert, AlertTitle, Button, Box } from "@mui/material";
import { IconTypes } from "../../../components/icons.types";

const ProductFieldsAlert = ({ onManageFields, categoryName }) => {
  return (
    <Alert
      severity="info"
      action={
        <Button
          color="info"
          size="small"
          startIcon={<IconTypes.Category />}
          onClick={onManageFields}
        >
          Manage Fields
        </Button>
      }
    >
      <AlertTitle>Need more product fields?</AlertTitle>
      <Box>
        Add custom fields (prices, images, specifications, etc.) to all products
        in the category <b>{categoryName}</b>.
      </Box>
    </Alert>
  );
};

export default ProductFieldsAlert;
