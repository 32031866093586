import { Box, Button, Tooltip, Typography } from "@mui/material";
import { DataTypeName } from "../../../features/data/data-types";
import { ProductField } from "../../../features/products/product";

const QuickFieldAdd = ({ onAddField }) => {
  const commonFields: ProductField[] = [
    { name: "Sale Price", dataType: DataTypeName.Money },
    { name: "Additional Image", dataType: DataTypeName.Image },
    { name: "Specifications", dataType: DataTypeName.RichText },
  ];

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <Typography variant="caption" color="text.secondary">
        Quick add:
      </Typography>
      {commonFields.map((field) => (
        <Tooltip
          key={field.name}
          title={`Add ${field.name} field to all products in this category`}
        >
          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={() => onAddField(field)}
            sx={{
              minWidth: "auto",
              px: 1,
              py: 0,
              fontSize: "0.75rem",
              textTransform: "none",
            }}
          >
            +{field.name}
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
};

export default QuickFieldAdd;
