import { useEffect, useRef, useState } from "react";
import { Badge, Box, Tab } from "@mui/material";

import { useAtom } from "jotai";
import { DesignerLayers } from "../components/DesignerLayers";
import { ClipboardPanel } from "../components/ClipboardPanel";
import { TemplateTabIndex } from "../catalogs/designer-types";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { adjustTemplateItems } from "../../../features/catalogs/catalog.template";
import { CatalogItemRef } from "../../../features/catalogs/catalogs";
import { EMPTY_GUID } from "../../../features/data/data-types";
import {
  clipboardAtom,
  getClipboardCount,
} from "../../../features/design/clipboard";
import { ProductPanel } from "../../products/list/ProductPanel";
import {
  DesignerSidebarRef,
  DesignerSidebar,
} from "../components/DesignerSidebar";
import { DesignerTheme } from "../components/DesignerTheme";
import { DesignerBoardApi, DesignerChange } from "../designer.board.api";
import { TemplateSettings } from "./TemplateSettings";
import { TemplateSidebarElements } from "./TemplateSidebarElements";
import { FixedPanel } from "../../../components/FixedPanel";

type Props = {
  item: CatalogItemRef;
  api: DesignerBoardApi;
  setBuilder: (builder: CatalogBuilder) => void;
  onChange: (change: DesignerChange) => void;
  onProductChange: (productId: string) => void;
  onTogglePanel: () => void;
  //marginWidth: number;
};
// enum TemplateTabIndex {
//   None = -1,
//   Products,
//   Design,
//   Layers,
//   Test,
//   Settings,
//   Clipboard,
// }

export const TemplateSidebar = ({
  setBuilder,
  api,
  item,
  onChange,
  onProductChange,
  onTogglePanel,
}: //marginWidth,
Props) => {
  const [tab, setTab] = useState(TemplateTabIndex.None);
  const [clipboard] = useAtom(clipboardAtom);
  const ref = useRef<DesignerSidebarRef>(null);

  const builder = api?.builder;

  useEffect(() => {
    if (item?.info.template.template.id === EMPTY_GUID) {
      setTab(TemplateTabIndex.Settings);
    }
  }, [item?.info.template.template.id]);

  return (
    <DesignerSidebar
      panelWidth={500}
      //marginWidth={marginWidth}
      ref={ref}
      containerId="#template-designer-container"
      isDark={true}
      // close={() => {
      //   setTab(TemplateTabIndex.None);
      //   onTogglePanel();
      // }}
      tab={tab}
      setTab={(v) => setTab(v as TemplateTabIndex)}
      tabEnum={TemplateTabIndex}
      name="template"
      onTogglePanel={onTogglePanel}
      panel={
        <>
          {tab === TemplateTabIndex.Products && (
            <ProductPanel
              hint="Select a product to preview with this card"
              onClick={(ref) => {
                onProductChange(ref.id);
              }}
            />
          )}
          {tab === TemplateTabIndex.Design && (
            <TemplateSidebarElements onChange={onChange} />
          )}
          {tab === TemplateTabIndex.Layers && (
            <DesignerLayers
              api={api}
              onChange={onChange}
              pageItems={item?.info.templateItems ?? []}
            />
          )}
          {tab === TemplateTabIndex.Test && (
            <FixedPanel overflow="auto">
              <Box
                sx={{
                  height: "100%",
                  px: 2,
                  mb: 3,
                }}
              >
                <DesignerTheme
                  catalog={builder.catalog}
                  onChange={(theme) => {
                    builder.catalog = { ...builder.catalog, ...theme };
                    builder.initPages();
                    onChange({ type: "refresh", refreshBoard: true });
                  }}
                />
              </Box>
            </FixedPanel>
          )}
          {/* {tab === TemplateTabIndex.Fields && (
            <ProductFieldsPanel
              categoryId={item?.info?.product?.product.categoryId}
              onInsertField={(placeholder) => {
                if (api?.selectedItem) {
                  // Insert into currently selected text box
                  insertFieldToEditor(
                    placeholder,
                    { product: item?.info?.product },
                    {
                      insertText: (text) => {
                        // Update the text content
                        onChange({
                          type: "update",
                          item: api.selectedItem,
                          content: text,
                          action: "insert field",
                        });
                      },
                    }
                  );
                }
              }}
            />
          )} */}
          {tab === TemplateTabIndex.Settings && (
            <TemplateSettings
              template={item.info.template.template}
              onChange={(t, action) => {
                adjustTemplateItems(item, t.width, t.height);
                item.info.template.template = t;
                builder.initPages();
                setBuilder(builder);
                onChange({ type: "refresh", action, clear: true });
              }}
            />
          )}
          {tab === TemplateTabIndex.Clipboard && (
            <ClipboardPanel
              builder={api.builder}
              onChange={api.onChange}
              meta="template"
            />
          )}
        </>
      }
      tabs={[
        // <Tabs
        //   value={tab}
        //   onChange={(e, v) => {
        //     setTab(v);
        //     if (!ref.current?.isOpen) onTogglePanel();
        //     ref.current?.toggle(true);
        //   }}
        //   orientation="vertical"
        // >

        <Tab
          key="products"
          icon={<IconTypes.Product />}
          label="Preview with product"
        />,
        <Tab key="design" icon={<IconTypes.Element />} label="Design" />,
        // <Tab key="fields" icon={<IconTypes.Fields />} label="Fields" />,
        <Tab
          key="layers"
          icon={<IconTypes.Layers />}
          label="Layers"
          sx={{
            borderBottom: "1px solid #555",
          }}
        />,

        <Tab key="test" icon={<IconTypes.Test />} label="Test" />,
        <Tab key="settings" icon={<IconTypes.Settings />} label="Settings" />,
        <Tab
          key="clipboard"
          icon={
            <Badge
              badgeContent={getClipboardCount(clipboard, "template")}
              color="primary"
            >
              <IconTypes.Clipboard />
            </Badge>
          }
          label="Clipboard"
        />,
      ]}
    />
  );
};
