import { useEffect, useRef, useState } from "react";

import {
  TextField,
  IconButton,
  SxProps,
  InputAdornment,
  Box,
} from "@mui/material";
import { useDebouncedEffect } from "../ui/useDebounceEffect";
import { IconTypes } from "./icons.types";

interface SearchBoxProps {
  onChange: (value: string) => void;
  value?: string;
  placeholder: string;
  sx?: SxProps;
  title?: string;
  expanded?: boolean;
  size?: "mini" | "small";
}

export const SearchBox = ({
  onChange,
  value,
  placeholder,
  sx,
  title,
  expanded,
  size,
}: SearchBoxProps) => {
  const [searchText, setSearchText] = useState(value ?? "");
  const [isExpanded, setExpanded] = useState(expanded ?? false);

  useDebouncedEffect(
    () => {
      onChange(searchText);
    },
    1000,
    [searchText],
    true
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);
  return (
    <Box
      sx={{
        width: size == "mini" ? 30 : 40,
        height: size == "mini" ? 30 : 40,
        position: "relative",
        backgroundColor: "inherit",
      }}
    >
      <TextField
        inputRef={inputRef}
        value={searchText}
        size="small"
        title={title}
        sx={{
          width: isExpanded ? 200 : 40,
          overflow: "hidden",
          position: "absolute",
          zIndex: 1000,
          backgroundColor: isExpanded ? "background.paper" : "inherit",

          "& fieldset": {
            borderColor: searchText && !isExpanded ? "primary" : undefined,
          },

          "& .MuiOutlinedInput-root": {
            pl: 1,
            pr: 0.5,
            cursor: isExpanded ? undefined : "pointer",
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
          " & input": {
            padding: size == "mini" ? "5px 8px" : undefined,
          },
          ...sx,
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (searchText !== event.target.value) {
            setSearchText(event.target.value);
          }
        }}
        onFocus={() => setExpanded(true)}
        onBlur={() => {
          setExpanded(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Escape") {
            event.preventDefault();
            setExpanded(false);
          }
          if (event.key === "Escape") {
            setSearchText("");
          }
        }}
        onClick={() => {
          if (!isExpanded) setExpanded(true);
        }}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                cursor: "pointer",
              }}
            >
              {searchText && !isExpanded ? (
                <IconTypes.SavedSearch
                  color="primary"
                  sx={{
                    "&:hover": {
                      color: "secondary.main",
                    },
                  }}
                />
              ) : (
                <IconTypes.Search
                  sx={{
                    "&:hover": {
                      color: "secondary.main",
                    },
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: searchText && (
            <InputAdornment position="end">
              <IconButton
                onMouseDown={(event) => {
                  event.preventDefault();
                  setSearchText("");
                  setExpanded(false);
                }}
              >
                <IconTypes.Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
