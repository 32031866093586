import * as Sentry from "@sentry/react";

export const initSentry = () => {
  const env = process.env.NODE_ENV;
  const options = {
    dsn: "https://00cf9894f0ed45d2b6525ff164dc0890@o43360.ingest.sentry.io/6513011",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        minReplayDuration: 10000,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    release: "cm-app@" + import.meta.env.VITE_VERSION,
    enabled: env !== "development",
    beforeSend(event) {
      const isSignalRError =
        event.message?.includes("Connection disconnected") ||
        event.message?.includes("Failed to start") ||
        event.message?.includes("Failed to complete");

      if (isSignalRError) {
        return null;
      }

      return event;
    },
  };
  Sentry.init(options);
};
